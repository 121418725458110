import React, { PropsWithChildren, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Dialog, IconButton, Typography } from "@mui/material";
import { FHButton } from "../FHButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FHNextImage } from "../FHNextImage";

const useStyles = makeStyles((theme) => ({
  root: {},
  paperRoot: {
    minWidth: 580,
    maxWidth: 690,
    padding: theme.spacing(3.2),
    backgroundColor: theme.palette.grey.A100,
    [theme.breakpoints.down("sm")]: {
      minWidth: 320,
      maxWidth: 342,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  logoIcon: {
    marginBottom: theme.spacing(0.4),
  },
  title: {
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: 32,
    lineHeight: "40px",
    fontWeight: 600,
  },
  subText: {
    fontSize: 16,
    lineHeight: "22px",
    fontWeight: 500,
  },
  closeBtn: {
    position: "absolute",
    padding: theme.spacing(0.5),
    right: 10,
    top: 10,
    color: theme.palette.text.primary,
    "& svg": {
      fontSize: 30,
    },
  },
  signUpBtn: {
    padding: theme.spacing(0.1, 2),
    maxWidth: 262,
    marginTop: theme.spacing(3.2),
    borderRadius: theme.shape.borderRadius,
  },
}));

export type BannerStickyPopProps = {
  title: string;
  message: string;
  primaryCTALabel?: string;
  primaryCTAUrl?: string;
  closeButtonIcon?: boolean;
  onCloseBtnClick?: () => void;
};

export const BannerStickyPop: React.FC<BannerStickyPopProps> = ({
  title,
  message,
  primaryCTALabel,
  primaryCTAUrl,
  closeButtonIcon,
  onCloseBtnClick = () => {},
}: PropsWithChildren<BannerStickyPopProps>) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return;
    setIsModalOpen(false);
  };

  return (
    <Dialog
      className={classes.root}
      open={isModalOpen}
      onClose={handleClose}
      classes={{
        paper: classes.paperRoot,
      }}
      disableEscapeKeyDown
    >
      {!!closeButtonIcon && (
        <IconButton
          className={classes.closeBtn}
          onClick={() => {
            onCloseBtnClick();
            setIsModalOpen(false);
          }}
          aria-label="Close"
        >
          <CloseRoundedIcon />
        </IconButton>
      )}
      <div className={classes.content}>
        <div className={classes.logoIcon}>
          <FHNextImage
            alt=""
            src="https://assets.forestholidays.co.uk/damprodblob/assets/fh_leaf_logo_350885e70d.svg"
            height={33}
            width={58}
          />
        </div>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.subText}>{message}</Typography>
      </div>
      {!!primaryCTAUrl && !!primaryCTALabel && (
        <FHButton
          className={classes.signUpBtn}
          fullWidth={false}
          href={primaryCTAUrl}
          onClick={() => {
            onCloseBtnClick();
            setIsModalOpen(false);
          }}
        >
          <strong>{primaryCTALabel}</strong>
        </FHButton>
      )}
    </Dialog>
  );
};
export default BannerStickyPop;
